/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PDFParser from "../../../PDFParsing/index";
import { Button, message, Modal, Spin } from "antd";
import { IoMdClose } from "react-icons/io";
import {
  getGridData,
  getPreviousGridData,
  saveGridData,
} from "../../../../services/admin-ai-viewer";
import { baseUrl } from "../../../../services/services.profile";
import TableReportGridAdmin from "../../../ui/CustomTable/TableReportGridAdmin";
import OCRSelectDrawer from "./OCRDrawer/OCRSelectDrawer";
import DocHistoryDrawer from "./DocHistoryDrawer/index";
import * as pdfjsLib from "pdfjs-dist/webpack";
import TranslateDrawer from "./translateDrawer/translateDrawer";
import { lablesMapping } from "../../../lablesMappingData";
import { getLabelByValue } from "./OCRDrawer/LanguagesData";
import {
  cancelTranslateData,
  saveTranslateData,
} from "../../../../services/translate";
import DocRequestDrawer from "./DocRequestDrawer";
import DocRequestHistoryDrawer from "./DocRequestHistoryDrawer";
import AdminAiMoreDrawer from "../AdminAiMoreDrawer/AdminAiMoreDrawer";

const AdminAiViewerDrawer = ({
  handleClose,
  tokenId,
  companyName,
  getOnBoardingApi,
  responseData,
  customerUi,
}) => {
  const [data, setData] = useState([]);
  const [discard, setDiscard] = useState([]);
  const [buttonList, setButtonList] = useState([]);
  const [response, setResponse] = useState();
  const [searchText, setSearchText] = useState(null);
  const [translateName, setTranslateName] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [PDF, setPdfUrl] = useState("");
  const [originalUrl, setOriginalUrl] = useState("");
  const [observations, setObservations] = useState("");
  const [activeButton, setActiveButton] = useState("CDD Report");
  const [keepChanges, setKeepChanges] = useState(false);
  const [discardChanges, setDiscardChanges] = useState(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [clearHighlights, setClearHighlights] = useState(false);
  const [isOpenOCR, setIsOpenOCR] = useState(false);
  const [isOpenDocHistory, setIsOpenDocHistory] = useState(false);
  const [isOpenDocRequestHistory, setIsOpenDocRequestHistory] = useState(false);
  const [saveTranslateLoading, setSaveTranslateLoading] = useState(false);
  const [isOpenTranslate, setIsOpenTranslate] = useState(false);
  const [isOpenDocRequest, setIsOpenDocRequest] = useState(false);
  const [pdfContainsText, setPdfContainsText] = useState(false);
  const [docReqOf, setDocReqOf] = useState("");
  const [manageOptionsDrawer, setManageOptionsDrawer] = useState(false);
  const [conformModal, setConformModal] = useState(false);
  const [docResponse, setDocResponse] = useState(null);
  const [docResponseData, setDocResponseData] = useState(null);
  const [ocrCheckedLoading, setOcrCheckedLoading] = useState(false);
  const [clickToShowLoadingGrid, setClickToShowLoadingGrid] = useState(false);
  const [clickToShowLoadingTranslate, setClickToShowLoadingTranslate] =
    useState(false);

  const [notSavedTraslations, setNotSavedTranslations] = useState({
    poi: null,
    ubo: null,
  });

  const [originalList, setOriginalList] = useState({
    poi: [],
    ubo: [],
  });

  const [originalData, setOriginalData] = useState({
    poi: null,
    ubo: null,
  });

  const [alreadyOcr, setAlreadyOcr] = useState({
    "Company Registration": { checked: false, ocr: false },
    "UBO Certificate": { checked: false, ocr: false },
  });

  const [clickedCell, setClickedCell] = useState({
    name: "",
    key: "",
    type: "",
  });

  const openConformModal = () => {
    setConformModal(true);
  };

  useEffect(() => {
    const checkPdfForTextOcrChecked = async (pdfUrl, type) => {
      const loadingTask = pdfjsLib.getDocument(baseUrl + "/" + pdfUrl);
      try {
        const pdf = await loadingTask.promise;
        const numPages = pdf.numPages;

        let hasText = true;

        for (let i = 1; i <= numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          if (textContent.items.length > 0) {
            hasText = false;
            break;
          }
        }
        setAlreadyOcr((prevState) => ({
          ...prevState,
          [type]: {
            checked: true,
            ocr: !hasText,
          },
        }));
      } catch (error) {
        return;
      }
    };

    const processPdf = async () => {
      setOcrCheckedLoading(true);
      const newPoi = docResponseData?.newPoi || {};
      const newUbo = docResponseData?.newUbo || {};

      if ("ocr" in newPoi && newPoi.ocr?.fileName) {
        setAlreadyOcr((prevState) => ({
          ...prevState,
          "Company Registration": {
            checked: true,
            ocr: true,
          },
        }));
      } else {
        const checkedPoi =
          buttonList.find((button) => button.name === "Company Registration")
            ?.fileName || null;

        if (checkedPoi && !newPoi?.ocr?.fileName) {
          await checkPdfForTextOcrChecked(checkedPoi, "Company Registration");
        }
      }

      if ("ocr" in newUbo && newUbo.ocr?.fileName) {
        setAlreadyOcr((prevState) => ({
          ...prevState,
          "UBO Certificate": {
            checked: true,
            ocr: true,
          },
        }));
      } else {
        const checkedUbo =
          buttonList.find((button) => button.name === "UBO Certificate")
            ?.fileName || null;

        if (checkedUbo && !newUbo?.ocr?.fileName) {
          await checkPdfForTextOcrChecked(checkedUbo, "UBO Certificate");
        }
      }

      setOcrCheckedLoading(false);
      setClickToShowLoadingGrid(false);
      setClickToShowLoadingTranslate(false);
    };

    processPdf();
  }, [buttonList, docResponseData]);

  useEffect(() => {
    if (pdfContainsText) {
      setAlreadyOcr((prevState) => ({
        ...prevState,
        [activeButton]: {
          checked: true,
          ocr: !pdfContainsText,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfContainsText]);

  const transformData = (data) => {
    const finalData = data.map((item) => ({
      key: item.key,
      name: item.categoryName,
      property: item.property,
      cddReport: item.cdd_doc.map((report, index) => ({
        id: index + 1,
        value: report,
        original: report,
      })),
      UBOCertificate: item.ubo_doc.map((ubo, index) => ({
        id: index + 1,
        value: ubo,
        original: ubo,
      })),
      companyRegistration: item.id_doc.map((reg, index) => ({
        id: index + 1,
        value: reg,
        original: reg,
      })),
      checks: item.consistency.map((check, index) => ({
        id: index + 1,
        value: check,
        original: check,
      })),
    }));
    finalData.splice(6, 0, {
      key: 15,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(8, 0, {
      key: 16,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });
    finalData.splice(10, 0, {
      key: 17,
      name: "",
      property: "blankRow",
      cddReport: [],
      UBOCertificate: [],
      companyRegistration: [],
      checks: [],
    });

    return finalData;
  };

  const checkType = (item) => {
    if (!item) return [];
    return typeof item === "string" ? [item] : item;
  };

  const handleCleanSearch = () => {
    setSearchText("");
    setClickedCell({
      name: "",
      key: "",
      type: "",
    });
    setClearHighlights(true);
  };

  const checkPdfForText = async (pdfUrl) => {
    const loadingTask = pdfjsLib.getDocument(baseUrl + "/" + pdfUrl);
    try {
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;

      let hasText = true;

      for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        if (textContent.items.length > 0) {
          hasText = false;
          break;
        }
      }
      setPdfContainsText(hasText);
    } catch (error) {
      setPdfContainsText(false);
    }
  };

  const checkOcrDocument = async () => {
    const otherButton =
      activeButton === "Company Registration"
        ? "UBO Certificate"
        : "Company Registration";

    if (
      (alreadyOcr[activeButton]?.checked && !alreadyOcr[activeButton]?.ocr) ||
      pdfContainsText
    ) {
      message.error(`Please perform OCR for the ${activeButton} document`);
      return false;
    }

    if (alreadyOcr[otherButton]?.checked && !alreadyOcr[otherButton]?.ocr) {
      message.error(`Please perform OCR for the ${otherButton} document`);
      return false;
    }

    return true;
  };

  const handleCancelTranslation = () => {
    if (!tokenId) {
      return;
    }

    const translatedData =
      selectedButton === "poi"
        ? notSavedTraslations.poi
        : selectedButton === "ubo" && notSavedTraslations.ubo;

    const originalSelectedViewList =
      selectedButton === "poi"
        ? originalList.poi
        : selectedButton === "ubo" && originalList.ubo;

    const ignoreCallCancelAPI =
      !!originalSelectedViewList.find(
        (x) =>
          !x.isAddedNow &&
          x.showAdmin &&
          x.translatedTo === translatedData?.translatedTo
      ) || translatedData?.showUser;

    (ignoreCallCancelAPI
      ? Promise.resolve()
      : cancelTranslateData({
          pdfName: translatedData?.translatedDoc,
        })
    ).then(() => {
      setPdfUrl(originalUrl);
      setNotSavedTranslations((prevState) => ({
        ...prevState,
        [selectedButton]: null,
      }));

      setOriginalList((prevState) => ({
        ...prevState,
        [selectedButton]: prevState[selectedButton].slice(
          0,
          prevState[selectedButton].length - 1
        ),
      }));

      setOriginalData((prevState) => ({
        ...prevState,
        [selectedButton]: null,
      }));
    });
  };

  const handleSaveTranslation = () => {
    if (!tokenId) {
      return;
    }

    setSaveTranslateLoading(true);

    const translatedDocData =
      selectedButton === "poi"
        ? notSavedTraslations.poi
        : selectedButton === "ubo" && notSavedTraslations.ubo;

    const payload = {
      token_id: tokenId,
      docType: selectedButton,
      translatedFrom: translatedDocData.translatedFrom,
      translatedTo: translatedDocData.translatedTo,
      translatedDoc: translatedDocData.translatedDoc,
    };

    saveTranslateData(payload)
      .then((res) => {
        if (res?.error) {
          setSaveTranslateLoading(false);
          return message.error("Translation saving failed, please try again");
        }
        message.success("Translation saved successfully");

        setNotSavedTranslations((prevState) => ({
          ...prevState,
          [selectedButton]: null,
        }));
      })
      .catch((error) => {
        message.error("Translation saving failed, please try again");
      })
      .finally(() => {
        setSaveTranslateLoading(false);
      });
  };

  useEffect(() => {
    if (activeButton !== "CDD Report" && PDF) {
      checkPdfForText(PDF);
    } else if (activeButton === "CDD Report") {
      setPdfContainsText(false);
    }
  }, [PDF, activeButton]);

  useEffect(() => {
    if (response) {
      const transformedData = transformData(response);
      setData(transformedData);
      setDiscard(transformedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const handleDiscardChanges = () => {
    setData(discard);
    setKeepChanges(false);
    setDiscardChanges(false);
  };

  useEffect(() => {
    if (data.length !== 0) {
      if (keepChanges) {
        handleCleanSearch();
      } else {
        setDiscard(data);
        setData(syncOriginalWithValue(data));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepChanges]);

  const syncOriginalWithValue = (data) => {
    return data.map((item) => ({
      ...item,
      cddReport: item.cddReport.map((field) => ({
        ...field,
        original: field.value,
      })),
      UBOCertificate: item.UBOCertificate.map((field) => ({
        ...field,
        original: field.value,
      })),
      companyRegistration: item.companyRegistration.map((field) => ({
        ...field,
        original: field.value,
      })),
      checks: item.checks.map((field) => ({
        ...field,
        original: field.value,
      })),
    }));
  };

  useEffect(() => {
    setIsPdfLoading(true);

    initialiseDataWithDocResponse(responseData?.userCertificateData);

    setIsPdfLoading(false);
  }, [responseData, tokenId]);

  useEffect(() => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    setKeepChanges(false);
    setIsTableLoading(true);

    getPreviousGridData(tokenId)
      .then((res) => {
        if (res?.error) {
          setIsTableLoading(false);
          return message.error(res?.error);
        }
        dataSet(res.gridData.ai_generated);
      })

      .finally(() => {
        setIsTableLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenId]);

  const initialiseDataWithDocResponse = (res) => {
    setDocResponse(res);
    setDocResponseData(res);

    const cddFileName = res?.newCdd?.fileName ? res?.newCdd?.fileName : "";
    const poiFileName =
      "ocr" in res?.newPoi && res?.newPoi?.ocr?.fileName
        ? res?.newPoi?.ocr?.fileName
        : res?.newPoi?.fileName
        ? res?.newPoi?.fileName
        : "";
    const uboFileName =
      "ocr" in res?.newUbo && res?.newUbo?.ocr?.fileName
        ? res?.newUbo?.ocr?.fileName
        : res?.newUbo?.fileName
        ? res?.newUbo?.fileName
        : "";

    setButtonList([
      {
        name: "CDD Report",
        fileName: cddFileName,
      },
      {
        name: "Company Registration",
        fileName: poiFileName,
      },
      {
        name: "UBO Certificate",
        fileName: uboFileName,
      },
    ]);

    setPdfUrl(cddFileName);

    // Check if translatedPoi key exists and it's not empty, then filter
    const filteredTranslatedPoi =
      res.newPoi?.translated && res.newPoi?.translated.length > 0
        ? res.newPoi?.translated.filter((item) => item.showAdmin)
        : [];

    // Check if translatedUbo key exists and it's not empty, then filter
    const filteredTranslatedUbo =
      res.newUbo?.translated && res.newUbo?.translated.length > 0
        ? res.newUbo?.translated.filter((item) => item.showAdmin)
        : [];

    setOriginalList({
      poi: filteredTranslatedPoi,
      ubo: filteredTranslatedUbo,
    });
  };

  // This is kind of reset which can be used when user re-sets the default file
  const resetOnDefaultDocChange = (res) => {
    setDocResponse(res);

    const cddFileName = res?.newCdd?.fileName ? res?.newCdd?.fileName : "";
    const poiFileName =
      "ocr" in res?.newPoi && res?.newPoi?.ocr?.fileName
        ? res?.newPoi?.ocr?.fileName
        : res?.newPoi?.fileName
        ? res?.newPoi?.fileName
        : "";
    const uboFileName =
      "ocr" in res?.newUbo && res?.newUbo?.ocr?.fileName
        ? res?.newUbo?.ocr?.fileName
        : res?.newUbo?.fileName
        ? res?.newUbo?.fileName
        : "";

    setButtonList([
      {
        name: "CDD Report",
        fileName: cddFileName,
      },
      {
        name: "Company Registration",
        fileName: poiFileName,
      },
      {
        name: "UBO Certificate",
        fileName: uboFileName,
      },
    ]);

    setPdfUrl(selectedButton === "poi" ? poiFileName : uboFileName);

    // Check if translatedPoi key exists and it's not empty, then filter
    const filteredTranslatedPoi =
      res.newPoi?.translated && res.newPoi?.translated.length > 0
        ? res.newPoi?.translated.filter((item) => item.showAdmin)
        : [];

    // Check if translatedUbo key exists and it's not empty, then filter
    const filteredTranslatedUbo =
      res.newUbo?.translated && res.newUbo?.translated.length > 0
        ? res.newUbo?.translated.filter((item) => item.showAdmin)
        : [];

    setOriginalData((prevState) => ({
      ...prevState,
      [selectedButton]: null,
    }));

    setOriginalList((prevState) => ({
      ...prevState,
      [selectedButton]:
        selectedButton === "poi"
          ? filteredTranslatedPoi
          : filteredTranslatedUbo,
    }));

    // This is to reset grid
    setObservations("");
    setResponse();
    handleCleanSearch();
    setData([]);
    setDiscard([]);

    // This is to reset Translate Buttons
    setKeepChanges(false);
    setNotSavedTranslations((prevState) => ({
      ...prevState,
      [selectedButton]: null,
    }));
  };

  const dataSet = (res) => {
    const dataset = Object.keys(lablesMapping).map((key, index) => {
      const data = res[key] || {};
      return {
        key: index + 1,
        property: key,
        categoryName: lablesMapping[key],
        cdd_doc: checkType(data.cdd_doc || "N/A"),
        id_doc: checkType(data.id_doc || "N/A"),
        ubo_doc: checkType(data.ubo_doc || "N/A"),
        consistency: checkType(data.consistency || "N/A"),
      };
    });
    setObservations(res["brief_risk_assessment"]);

    setResponse(dataset);
  };

  const createGrid = async () => {
    if (ocrCheckedLoading) {
      setClickToShowLoadingGrid(true);
      return;
    }

    const ocrCheckPassed = await checkOcrDocument();

    if (!ocrCheckPassed) {
      return;
    }

    if (!tokenId) {
      return message.error("Token id missing");
    }

    let missingDocs = [];
    if (!buttonList[0].fileName) missingDocs.push("CDD");
    if (!buttonList[1].fileName) missingDocs.push("POI");
    if (!buttonList[2].fileName) missingDocs.push("UBO");

    if (missingDocs.length > 0) {
      // Format the message to include commas and "and" before the last item
      let formattedMessage =
        missingDocs.length > 1
          ? `${missingDocs.slice(0, -1).join(", ")} and ${missingDocs.slice(
              -1
            )}`
          : missingDocs[0];

      return message.error(
        `${formattedMessage} document${
          missingDocs.length > 1 ? "s" : ""
        } is required`
      );
    }

    setKeepChanges(false);
    setIsTableLoading(true);
    handleCleanSearch();

    getGridData({ token_id: tokenId })
      .then((res) => {
        if (res?.error) {
          setIsTableLoading(false);
          return message.error(res?.error);
        }
        dataSet(res.gridData);
      })

      .finally(() => {
        setIsTableLoading(false);
      });
  };

  const handleDocRequest = (type) => {
    setDocReqOf(type);
    setIsOpenDocRequest(true);
  };

  const getNewGridDataPayloads = () => {
    const payload = {
      brief_risk_assessment: observations,
    };

    Object.keys(lablesMapping).forEach((key) => {
      const gridDataByKey = data.find((x) => x.property === key);
      const isMulti =
        key === "directors_officers" || key === "ubos" ? true : false;
      if (gridDataByKey) {
        payload[key] = {
          cdd_doc: mapDataValues(gridDataByKey, "cddReport", isMulti),
          id_doc: mapDataValues(gridDataByKey, "companyRegistration", isMulti),
          ubo_doc: mapDataValues(gridDataByKey, "UBOCertificate", isMulti),
          consistency: mapDataValues(gridDataByKey, "checks"),
        };
      } else {
        payload[key] = {
          cdd_doc: "N/A",
          id_doc: "N/A",
          ubo_doc: "N/A",
          consistency: "N/A",
        };
      }
    });

    return payload;
  };

  const mapDataValues = (data, field, isMulti = false) => {
    const values = data[field].map((item) => item.value);
    if (isMulti) {
      return values;
    } else {
      return values.join("");
    }
  };

  const handleSaveGrid = () => {
    if (!tokenId) {
      return message.error("Token id missing");
    }
    setIsSaveLoading(true);

    const gridDataPayload = getNewGridDataPayloads();

    saveGridData({ token_id: tokenId, gridData: gridDataPayload })
      .then((res) => {
        if (res?.error) {
          setIsSaveLoading(false);
          return message.error(res?.error?.message);
        }
        setIsSaveLoading(false);
        message.success(res?.message);
        handleClose();
        getOnBoardingApi?.();
      })
      .catch((error) => {
        setIsSaveLoading(false);
        console.log(error);
      });
  };

  const stickyHeader = () => (
    <div className="flex lg:pb-6 pb-4 xl:gap-4 gap-2 justify-between flex-wrap-reverse bg-white w-full">
      <div className="flex xl:gap-4 gap-2">
        {buttonList.map((item, index) => (
          <button
            type="button"
            key={index}
            onClick={() => {
              if (
                (activeButton === "Company Registration" &&
                  notSavedTraslations.poi) ||
                (activeButton === "UBO Certificate" && notSavedTraslations.ubo)
              ) {
                openConformModal();
              } else {
                setPdfUrl(
                  item.name === "Company Registration"
                    ? originalData.poi?.translatedDoc
                      ? originalData.poi?.translatedDoc
                      : item.fileName
                    : item.name === "UBO Certificate"
                    ? originalData.ubo?.translatedDoc
                      ? originalData.ubo?.translatedDoc
                      : item.fileName
                    : item.fileName
                );
                setOriginalUrl(item.fileName);
                handleCleanSearch();
                setActiveButton(item.name);
                setSelectedButton(
                  item.name === "Company Registration"
                    ? "poi"
                    : item.name === "UBO Certificate" && "ubo"
                );
              }
            }}
            className={`text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide px-4 h-8 rounded-lg ${
              activeButton === item.name
                ? "text-primary bg-white"
                : "text-white bg-primary"
            }`}
          >
            {item.name}
          </button>
        ))}
      </div>
      <div className="flex xl:gap-4 gap-2 justify-end ml-auto">
        <Button
          type="primary"
          onClick={() => {
            (activeButton === "Company Registration" &&
              notSavedTraslations.poi) ||
            (activeButton === "UBO Certificate" && notSavedTraslations.ubo)
              ? openConformModal()
              : createGrid();
          }}
          loading={
            (clickToShowLoadingGrid && ocrCheckedLoading) || isTableLoading
          }
          className="text-xs flex !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
        >
          Create Grid
        </Button>
        <Button
          type="primary"
          loading={isSaveLoading}
          disabled={data.length === 0 || isTableLoading}
          onClick={() => {
            (activeButton === "Company Registration" &&
              notSavedTraslations.poi) ||
            (activeButton === "UBO Certificate" && notSavedTraslations.ubo)
              ? openConformModal()
              : handleSaveGrid();
          }}
          className="text-xs flex cursor-pointer !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
        >
          Save Grid
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className="h-full">
        <div
          className="flex lg:hidden justify-end cursor-pointer pr-4 pt-4 pb-2 z-30 sticky top-0 bg-white"
          onClick={handleClose}
        >
          <IoMdClose
            size={24}
            className="text-gray-500  hover:text-primary flex justify-center items-center "
          />
        </div>
        <div className="lg:hidden px-4 pt-2 sticky top-0 z-50">
          {stickyHeader()}
        </div>
        <div className="flex lg:flex-row-reverse flex-col lg:h-full md:h-[calc(100vh-104px)] h-[calc(100vh-144px)] overflow-auto ">
          <div className="flex lg:w-[60%] w-full flex-col lg:ml-4">
            {!isPdfLoading && (
              <>
                <div
                  className="lg:flex hidden justify-end cursor-pointer pr-4 pt-4 mb-2 sticky top-0 bg-white"
                  onClick={handleClose}
                >
                  <IoMdClose
                    size={24}
                    className="text-gray-500  hover:text-primary flex justify-center items-center"
                  />
                </div>

                <div className="flex flex-col lg:gap-6 gap-4 lg:h-[calc(100vh-47px)] lg:overflow-y-auto pr-4 pl-4 lg:pl-0 pb-4">
                  <div className="flex flex-col">
                    <div className="lg:flex hidden sticky top-0 z-50">
                      {stickyHeader()}
                    </div>
                    <TableReportGridAdmin
                      setSearchText={setSearchText}
                      PDF={baseUrl + "/" + PDF}
                      keepChanges={keepChanges}
                      isTableLoading={isTableLoading}
                      data={data}
                      setData={setData}
                      setClickedCell={setClickedCell}
                      clickedCell={clickedCell}
                      handleCleanSearch={handleCleanSearch}
                    />
                  </div>
                  {response && (
                    <div className="flex flex-wrap justify-end xl:gap-4 gap-2">
                      {keepChanges && (
                        <>
                          <Button
                            onClick={() => {
                              (activeButton === "Company Registration" &&
                                notSavedTraslations.poi) ||
                              (activeButton === "UBO Certificate" &&
                                notSavedTraslations.ubo)
                                ? openConformModal()
                                : setDiscardChanges(true);
                              handleDiscardChanges();
                            }}
                            loading={discardChanges}
                            className="text-xs flex !border-primary border-2 whitespace-nowrap !bg-white font-semibold tracking-wide !text-primary px-4 h-8 rounded-lg"
                          >
                            Discard These Changes
                          </Button>

                          <button
                            type="button"
                            onClick={() => {
                              (activeButton === "Company Registration" &&
                                notSavedTraslations.poi) ||
                              (activeButton === "UBO Certificate" &&
                                notSavedTraslations.ubo)
                                ? openConformModal()
                                : setKeepChanges(false);
                            }}
                            className="text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide text-white bg-primary px-4 h-8 rounded-lg"
                          >
                            Keep These Changes
                          </button>
                        </>
                      )}
                      {!keepChanges && (
                        <Button
                          type="button"
                          disabled={isTableLoading}
                          onClick={() => {
                            (activeButton === "Company Registration" &&
                              notSavedTraslations.poi) ||
                            (activeButton === "UBO Certificate" &&
                              notSavedTraslations.ubo)
                              ? openConformModal()
                              : setKeepChanges(true);
                          }}
                          className="text-xs !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-primary px-4 h-8 rounded-lg"
                        >
                          Edit Grid
                        </Button>
                      )}
                    </div>
                  )}

                  {observations && (
                    <div>
                      <span className="font-bold">Observations:</span>{" "}
                      {observations}
                    </div>
                  )}
                  <div className="flex w-full flex-wrap gap-4 mt-auto justify-between">
                    <div className="flex xl:gap-4 gap-2">
                      {activeButton !== "CDD Report" && (
                        <>
                          {((activeButton === "Company Registration" &&
                            notSavedTraslations.poi) ||
                            (activeButton === "UBO Certificate" &&
                              notSavedTraslations.ubo)) && (
                            <>
                              <Button
                                loading={saveTranslateLoading}
                                onClick={() => handleSaveTranslation()}
                                className="text-xs flex !border-[#ffc000] border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-[#ffc000] px-4 h-8 rounded-lg"
                              >
                                Save translation
                              </Button>
                            </>
                          )}

                          {((activeButton === "Company Registration" &&
                            !notSavedTraslations.poi) ||
                            (activeButton === "UBO Certificate" &&
                              !notSavedTraslations.ubo)) && (
                            <Button
                              onClick={async () => {
                                if (
                                  [
                                    "Company Registration",
                                    "UBO Certificate",
                                  ].includes(activeButton)
                                ) {
                                  if (ocrCheckedLoading) {
                                    setClickToShowLoadingTranslate(true);
                                    return;
                                  }
                                  const ocrCheckPassed =
                                    await checkOcrDocument();
                                  if (!ocrCheckPassed) {
                                    return;
                                  } else {
                                    setIsOpenTranslate(true);
                                    setTranslateName(activeButton);
                                  }
                                }
                              }}
                              loading={
                                clickToShowLoadingTranslate && ocrCheckedLoading
                              }
                              type="primary"
                              className="flex text-xs border-2 whitespace-nowrap font-semibold tracking-wide !text-white px-4 h-8 rounded-lg"
                            >
                              Translate
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                    <div className="flex flex-wrap justify-end  xl:gap-4 gap-2 ">
                      {pdfContainsText && (
                        <Button
                          type="primary"
                          onClick={() => setIsOpenOCR(true)}
                          className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
                        >
                          Perform OCR
                        </Button>
                      )}
                      {!customerUi && (
                        <Button
                          type="primary"
                          onClick={() => {
                            (activeButton === "Company Registration" &&
                              notSavedTraslations.poi) ||
                            (activeButton === "UBO Certificate" &&
                              notSavedTraslations.ubo)
                              ? openConformModal()
                              : setManageOptionsDrawer(true);
                          }}
                          className={`text-xs border-primary border-2 whitespace-nowrap font-semibold tracking-wide px-4 h-8 rounded-lg 
                            
                               text-primary bg-white
                          `}
                        >
                          More Options
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="lg:w-[40%] w-full flex flex-col pl-4 pr-4 lg:pr-0 py-4">
            <div className="text text-center font-semibold mb-2 flex flex-col h-full">
              {activeButton} for {companyName}
              {((selectedButton === "poi" && originalList?.poi.length > 0) ||
                (selectedButton === "ubo" && originalList?.ubo.length > 0)) && (
                <div className="flex flex-col justify-start items-center">
                  {(selectedButton === "poi"
                    ? originalData.poi
                    : selectedButton === "ubo" && originalData.ubo) && (
                    <div>
                      <span>
                        AI Translated from{" "}
                        {selectedButton === "poi"
                          ? originalData.poi?.translatedFrom
                          : selectedButton === "ubo" &&
                            originalData.ubo?.translatedFrom}{" "}
                        into{" "}
                        {selectedButton === "poi"
                          ? getLabelByValue(originalData.poi?.translatedTo)
                          : selectedButton === "ubo" &&
                            getLabelByValue(
                              originalData.ubo?.translatedTo
                            )}{" "}
                      </span>
                      <span className="mx-1">-</span>
                      <a
                        className="!text-blue-600"
                        onClick={() => {
                          setPdfUrl(
                            selectedButton === "poi"
                              ? buttonList.find(
                                  (button) => button.name === activeButton
                                ).fileName
                              : selectedButton === "ubo" &&
                                  buttonList.find(
                                    (button) => button.name === activeButton
                                  ).fileName
                          );
                          setOriginalData((prevState) => ({
                            ...prevState,
                            [selectedButton]: null,
                          }));
                        }}
                      >
                        Show original document
                      </a>
                    </div>
                  )}

                  {!(selectedButton === "poi"
                    ? originalData.poi
                    : selectedButton === "ubo" && originalData.ubo) && (
                    <div>
                      <span>Show the document translated in</span>
                      <span className="mx-1">:</span>

                      {(selectedButton === "poi"
                        ? originalList?.poi || []
                        : selectedButton === "ubo"
                        ? originalList?.ubo || []
                        : []
                      ).map((value, index) => (
                        <a
                          key={index}
                          className="!text-blue-600 ml-1"
                          onClick={() => {
                            setOriginalData((prevState) => ({
                              ...prevState,
                              [selectedButton]: value,
                            }));
                            setPdfUrl(value.translatedDoc);
                          }}
                        >
                          {getLabelByValue(value.translatedTo)}
                          {(selectedButton === "poi"
                            ? originalList?.poi || []
                            : selectedButton === "ubo"
                            ? originalList?.ubo || []
                            : []
                          ).length -
                            1 ===
                          index
                            ? ""
                            : ", "}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {isPdfLoading ? (
                <Spin className="mt-20" />
              ) : (
                <PDFParser
                  PDFUrl={baseUrl + "/" + PDF}
                  searchText={searchText}
                  clearHighlights={clearHighlights}
                  setClearHighlights={setClearHighlights}
                  heightSet={true}
                />
              )}
            </div>
          </div>
        </div>
        {conformModal && (
          <Modal
            title="Discard Translation"
            open={conformModal}
            onCancel={() => setConformModal(false)}
            footer={null}
          >
            <p>Are you sure you want to discard this translation?</p>
            <div className="mt-4">
              {((activeButton === "Company Registration" &&
                notSavedTraslations.poi) ||
                (activeButton === "UBO Certificate" &&
                  notSavedTraslations.ubo)) && (
                <div className="flex justify-end gap-3">
                  <Button
                    onClick={() => {
                      handleCancelTranslation();
                      setConformModal(false);
                    }}
                    className="flex text-xs !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-primary !bg-white px-4 h-8 rounded-lg"
                  >
                    Discard
                  </Button>
                  <Button
                    loading={saveTranslateLoading}
                    onClick={() => {
                      handleSaveTranslation();
                      setConformModal(false);
                    }}
                    className="text-xs flex !border-primary border-2 whitespace-nowrap font-semibold tracking-wide !text-white !bg-primary px-4 h-8 rounded-lg"
                  >
                    Save translation
                  </Button>
                </div>
              )}
            </div>
          </Modal>
        )}
      </div>
      {isOpenOCR && (
        <OCRSelectDrawer
          isOpenOCR={isOpenOCR}
          setIsOpenOCR={setIsOpenOCR}
          tokenId={tokenId}
          activeButton={activeButton}
          setPdfUrl={setPdfUrl}
          setButtonList={setButtonList}
          setOriginalUrl={setOriginalUrl}
          setPdfContainsText={setPdfContainsText}
        />
      )}
      {isOpenDocHistory && (
        <DocHistoryDrawer
          isOpenDocHistory={isOpenDocHistory}
          setIsOpenDocHistory={setIsOpenDocHistory}
          tokenId={tokenId}
          activeButton={activeButton}
          docResponse={docResponse}
          resetOnDefaultDocChange={resetOnDefaultDocChange}
        />
      )}
      {isOpenDocRequest && (
        <DocRequestDrawer
          isOpenDocRequest={isOpenDocRequest}
          setIsOpenDocRequest={setIsOpenDocRequest}
          tokenId={tokenId}
          docType={docReqOf}
        />
      )}
      {isOpenDocRequestHistory && (
        <DocRequestHistoryDrawer
          isOpenDocRequestHistory={isOpenDocRequestHistory}
          setIsOpenDocRequestHistory={setIsOpenDocRequestHistory}
          tokenId={tokenId}
        />
      )}
      {isOpenTranslate && (
        <TranslateDrawer
          isOpenTranslate={isOpenTranslate}
          handleClose={() => setIsOpenTranslate(false)}
          translateName={translateName}
          tokenId={tokenId}
          setNotSavedTranslations={setNotSavedTranslations}
          originalUrl={originalUrl}
          setOriginalList={setOriginalList}
          setOriginalData={setOriginalData}
          setPdfUrl={setPdfUrl}
        />
      )}

      {manageOptionsDrawer && (
        <AdminAiMoreDrawer
          manageOptionsDrawer={manageOptionsDrawer}
          setManageOptionsDrawerClose={() => setManageOptionsDrawer(false)}
          setIsOpenDocRequestHistory={setIsOpenDocRequestHistory}
          setIsOpenDocHistory={setIsOpenDocHistory}
          handleDocRequest={handleDocRequest}
          activeButton={activeButton}
        />
      )}
    </>
  );
};

export default AdminAiViewerDrawer;
